.loader-wrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 9998;
  background-color: #ffffff;

}

.loading {
  z-index: 9999;
  transform: scale(25%, 25%);
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: url('../../../public/luiss_logo_animation.gif') 50% 50% no-repeat #ffffff;

}